import { MrDialog } from '@mrhenry/wp--mr-interactive';

class MrLocationDataErrorDialog extends MrDialog {
	override firstFocusableElement(): HTMLElement | void {
		/**
		 * Override the super so the focus isn't moved to this dialog when it
		 * is shown. The dialog is informative, so we let the user keep their
		 * focus on the currently active element.
		 */
		if ( document.activeElement && ( document.activeElement instanceof HTMLElement ) ) {
			return document.activeElement;
		}

		return super.firstFocusableElement();
	}
}

customElements.define( 'mr-location-data-error-dialog', MrLocationDataErrorDialog );
