export const i18nDaysOfWeek = {
	nl: [
		'Zondag',
		'Maandag',
		'Dinsdag',
		'Woensdag',
		'Donderdag',
		'Vrijdag',
		'Zaterdag',

	],
	en: [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	],
};

export const i18nToday = {
	nl: 'Vandaag',
	en: 'Today',
};

export const i18nTomorrow = {
	nl: 'Morgen',
	en: 'Tomorrow',
};
