export function templateOccupancyStudy360( lang: string, locations: number, visitors: number ): string {
	if ( 'en' === lang ) {
		return `There ${studentsPart( lang, visitors )} revising ${locationsPart( lang, locations )} in Antwerp.`;
	}

	return `Momenteel ${studentsPart( lang, visitors )} ${locationsPart( lang, locations )} in Antwerpen aan het studeren.`;
}

function studentsPart( lang: string, amount: number ): string {
	switch ( lang ) {
		case 'en':
			switch ( amount ) {
				case 0:
					return 'are currently no students';
				case 1:
					return 'is currently 1 student';
				default:
					return `are currently ${amount} students`;
			}

		default:

			switch ( amount ) {
				case 0:
					return 'zijn er geen studenten';
				case 1:
					return 'is er 1 student';
				default:
					return `zijn er ${amount} studenten`;
			}
	}
}

function locationsPart( lang: string, amount: number ): string {
	switch ( lang ) {
		case 'en':
			switch ( amount ) {
				case 0:
					return 'at our locations';
				case 1:
					return 'at one of our locations';
				default:
					return `at ${amount} of our locations`;
			}

		default:

			switch ( amount ) {
				case 0:
					return 'op onze locaties';
				case 1:
					return 'op één van onze locaties';
				default:
					return `op ${amount} van onze locaties`;
			}
	}
}
