import { mapboxBuildGeoJSON, mapboxInitializeMap, mapboxLoadDependencies, mapboxRenderMarkers, mapboxRenderUserPosition } from './mapbox/mapbox';

class MrLocationPicker extends HTMLElement {
	connectedCallback() {
		requestAnimationFrame( () => {
			this._init();
		} );
	}

	_init() {
		mapboxLoadDependencies().then( () => {
			window.mapboxgl.accessToken = 'pk.eyJ1IjoibXJoZW5yeSIsImEiOiJjaWkxczFpbmgwMGVhdHptMGJ4MmhiMjhyIn0._tUMWvSNQW3qk1-CuvH1-g';

			this.elements = {};
			this.elements.locations = Array.from( this.getElementsByClassName( 'js-location-picker-location' ) );
			this.elements.map = this.getElementsByClassName( 'js-location-picker-map' )[0];

			return mapboxInitializeMap( this.elements.map, [
				4.414358,
				51.2120714,
			] );
		} ).then( ( map ) => {
			this.map = map;
			this.markers = mapboxRenderMarkers( mapboxBuildGeoJSON( this.elements.locations ), map );

			window.addEventListener( 'filter-changed', () => {
				mapboxRenderMarkers( mapboxBuildGeoJSON( this.elements.locations ), this.map );
			} );

			this._addEventListeners();
		} ).catch( ( err ) => {
			console.warn( err );
			document.getElementById( 'location-data-error-dialog' )?.updateState( 'open' );
		} );
	}

	_addEventListeners() {
		this.querySelector( '[data-find-my-location-button]' )?.addEventListener( 'click', () => {
			this.focusUserLocation();
		} );

		let currentPopupKey;

		const popup = new window.mapboxgl.Popup( {
			closeButton: true,
			closeOnClick: false,
			offset: 18,
		} );

		const showPopup = ( feature ) => {
			if ( popup ) {
				popup.remove();
			}

			const wrapped = `<div class="location-picker__popup">${document.getElementById( feature.properties.popup ).innerHTML}</div>`;

			popup.setLngLat( feature.geometry.coordinates )
				.setHTML( wrapped )
				.addTo( this.map )
				.setMaxWidth( '400px' ); // larger than the actual value which is 390px
		};

		const hidePopup = () => {
			if ( popup ) {
				popup.remove();
			}
		};

		this.map.on( 'mouseenter', 'markers', () => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: 'pointer',
			} );
		} );

		this.map.on( 'click', 'markers', ( e ) => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: 'pointer',
			} );

			const [
				feature,
			] = e.features;

			if ( currentPopupKey === feature.properties.id ) {
				hidePopup();
				currentPopupKey = undefined;
			} else {
				showPopup( feature );
				currentPopupKey = feature.properties.id;
			}
		} );

		this.map.on( 'mouseleave', 'markers', () => {
			const canvas = this.map.getCanvas();
			Object.assign( canvas.style, {
				cursor: '',
			} );
		} );
	}

	focusUserLocation() {
		if ( navigator.geolocation ) {
			navigator.geolocation.getCurrentPosition( ( position ) => {
				const {
					latitude, longitude,
				} = position.coords;
				mapboxRenderUserPosition( latitude, longitude, this.map );
			} );
		}
	}
}

customElements.define( 'mr-location-picker', MrLocationPicker );
