import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-toggle', {
	attributes: [
		{
			attribute: 'target',
			type: 'string',
		},
		{
			attribute: 'toggle',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};

			if ( this.target ) {
				this.elements.target = document.querySelector( this.target );
			}
		}

		bind() {
			this.on( 'click', () => {
				if ( this.elements.target ) {
					this.elements.target.classList.toggle( this.toggle );
				}
			} );
		}
	},
} );
