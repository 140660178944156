import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-sidenav', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.buttons = Array.from( this.el.querySelectorAll( 'button' ) );
			this.elements.dashboardContent = Array.from( document.querySelectorAll( '.js-dashboard-item' ) );
		}

		bind() {
			this.elements.buttons.forEach( ( button ) => {
				this.on( 'click', ( e, target ) => {
					e.preventDefault();
					this.toggle( target );
				}, button );
			} );
		}

		hide( button ) {
			button.classList.remove( 'is-active' );

			const activeContentItem = this.elements.dashboardContent.find( ( contentItem ) => {
				return contentItem.getAttribute( 'aria-labelledby' ) === button.id;
			} );

			activeContentItem.setAttribute( 'hidden', 'hidden' );
		}

		show( button ) {
			button.classList.add( 'is-active' );

			const activeContentItem = this.elements.dashboardContent.find( ( contentItem ) => {
				return contentItem.getAttribute( 'aria-labelledby' ) === button.id;
			} );

			activeContentItem.removeAttribute( 'hidden' );
		}

		toggle( button ) {
			this.hideAll();
			this.show( button );
		}

		hideAll() {
			this.elements.buttons.forEach( ( button ) => {
				this.hide( button );
			} );
		}
	},
} );
