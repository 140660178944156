import { render } from 'lit';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { init } from '@mrhenry/wp--mr-interactive';
import { MutationFeedback } from './data';
import { renderVisitorRegistrationForm, renderVisitorRegistrationSuccess } from './view';
import '../globals/globals';

const REGISTER_VISITOR_MUTATION = `
	mutation (
		$name: String!,
		$email: String!,
		$contactAboutFutureEditionsConsent: Boolean!,
		$additionalInformation: JSONObject!,
		$language: Language!,
	) {
		registerVisitor(
			input: {
				name: $name,
				email: $email,
				contactAboutFutureEditionsConsent: $contactAboutFutureEditionsConsent,
				additionalInformation: $additionalInformation
			},
			language: $language
		)
	}
`;

type RegistrationResult = {
	success: boolean;
	messages: string[];
}

function visitorRegistrationForm( parent: HTMLElement ) {

	async function handleSubmit( event: Event ) {
		event.preventDefault();
		event.stopPropagation();

		const form = event.target as HTMLFormElement;
		if ( !form ) {
			return;
		}

		const result = await submitData(
			form.firstName.value,
			form.lastName.value,
			form.email.value,
			form.phoneNumber.value,
			form.studentNumber.value,
			form.school.value,
			form.education.value,
			form.communicationOptIn.checked
		);

		if ( result && !result.success ) {
			const withErrors = result.messages;

			render(
				renderVisitorRegistrationForm( handleSubmit, withErrors ),
				parent
			);

			return;
		}

		render(
			renderVisitorRegistrationSuccess(),
			parent
		);

		document.getElementById( 'visitor-registration-form' )?.scrollIntoView( {
			block: 'start',
		} );

		return;
	}

	async function submitData(
		firstName: string,
		lastName: string,
		email: string,
		phoneNumber: string,
		studentNumber: string,
		school: string,
		education: string,
		communicationOptIn: boolean
	): Promise<RegistrationResult> {

		try {
			const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify( {
					query: REGISTER_VISITOR_MUTATION,
					variables: {
						name: firstName + ' ' + lastName,
						email: email,
						contactAboutFutureEditionsConsent: communicationOptIn,
						additionalInformation: {
							phoneNumber: phoneNumber,
							studentNumber: studentNumber,
							school: school,
							education: education,
						},
						language: document.documentElement.getAttribute( 'lang' )?.toUpperCase() || 'NL',
					},
				} ),
			} );

			if ( !response.ok ) {
				bugsnagClient.notify( new Error( `[study360 signup student] API Error: ${response.status}: ${response.statusText}` ) );

				return {
					success: false,
					messages: [
						window.translations.errorMessages.generic,
					],
				};
			}

			const responseBody = await response.json();

			if ( responseBody.data?.registerVisitor === MutationFeedback.OK ) {
				return {
					success: true,
					messages: [],
				};
			}

			bugsnagClient.notify( new Error( `[study360 signup student] API Error: ${responseBody}` ) );

			const messages: string[] = [];
			responseBody.errors?.forEach( ( message:string ) => {
				messages.push( message );
			} );

			return {
				success: false,
				messages: messages,
			};
		} catch ( err ) {
			bugsnagClient.notify( err );

			return {
				success: false,
				messages: [
					window.translations.errorMessages.generic,
					err,
				],
			};
		}
	}

	render(
		renderVisitorRegistrationForm( handleSubmit, [] ),
		parent
	);
}

init( (): boolean => {
	const visitorRegistrationElement = document.getElementById( 'visitor-registration-form' );

	if ( !visitorRegistrationElement ) {
		return false;
	}

	while ( visitorRegistrationElement.firstChild ) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		visitorRegistrationElement.removeChild( visitorRegistrationElement.lastChild! );
	}

	visitorRegistrationForm( visitorRegistrationElement );

	return true;
} );
