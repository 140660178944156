class MrLocationsFilter extends HTMLElement {
	#clickHandler = ( e: Event ) => {
		const target = e.target;
		if ( !isLocationButtonElement( target ) ) {
			return;
		}

		const clickedFilterButton = target.closest( '[data-location-filter-button]' );
		if ( !clickedFilterButton ) {
			return;
		}

		if ( clickedFilterButton.getAttribute( 'aria-pressed' ) === 'true' ) {
			clickedFilterButton.setAttribute( 'aria-pressed', 'false' );
		} else {
			clickedFilterButton.setAttribute( 'aria-pressed', 'true' );
		}

		const filters = this.querySelectorAll<HTMLElement>( '[data-location-filter-button]' );
		if ( !filters.length ) {
			return;
		}

		let query = '';
		let onlyCurrentlyOpen = false;

		filters.forEach( ( filter ) => {
			const filterType = filter.getAttribute( 'data-filter-type' );
			const filterValue = filter.getAttribute( 'data-filter-value' );
			const active = filter.getAttribute( 'aria-pressed' ) === 'true';

			if ( filterType === 'location-service' && active ) {
				query += `[data-location-services~="${filterValue}"]`;
			}

			if ( filterType === 'open-status' && active ) {
				onlyCurrentlyOpen = true;
			}
		} );

		document.querySelectorAll<HTMLElement>( '[filterable]' ).forEach( ( item ) => {
			item.setAttribute( 'hidden', '' );
		} );

		document.querySelectorAll<HTMLElement>( '[filterable]' + query ).forEach( ( item ) => {
			if ( !onlyCurrentlyOpen ) {
				item.removeAttribute( 'hidden' );

				return;
			}

			if ( item.querySelector( '[location-is-currently-open]' ) !== null ) {
				item.removeAttribute( 'hidden' );
			}
		} );

		const noResultsMessage = document.querySelector<HTMLElement>( '[data-no-location-results]' );
		if ( noResultsMessage ) {
			noResultsMessage.removeAttribute( 'hidden' );
			if ( document.querySelectorAll<HTMLElement>( '[filterable]:not([hidden])' ).length > 0 ) {
				noResultsMessage.setAttribute( 'hidden', 'hidden' );
			}
		}

		this.dispatchEvent( new CustomEvent( 'filter-changed', {
			bubbles: true,
		} ) );
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}
}

customElements.define( 'mr-locations-filter', MrLocationsFilter );

function isLocationButtonElement( el: EventTarget|null ): el is HTMLElement {
	return !!el && ( 'className' in el ) && ( 'getAttribute' in el ) && ( 'closest' in el );
}
