import { render } from 'lit';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { init } from '@mrhenry/wp--mr-interactive';
import { MutationFeedback } from './data';
import { renderLocationRegistrationForm, renderLocationRegistrationSuccess } from './view';

const REGISTER_LOCATION_MUTATION = `
	mutation (
		$name: String!,
		$additionalInformation: JSONObject!
	) {
		registerLocation(
			input: {
				name: $name,
				additionalInformation: $additionalInformation,
			}
		)
	}
`;

type LocationRegistrationResult = {
	success: boolean;
	messages: string[];
}

function locationRegistrationForm( parent: HTMLElement ) {

	async function handleSubmit( event: Event ) {
		event.preventDefault();
		event.stopPropagation();

		const form = event.target as HTMLFormElement;
		if ( !form ) {
			return;
		}

		const servicesArray:Array<string> = [];
		const servicesOptions = form.querySelectorAll( '[name="services[]"]' ) as NodeListOf<HTMLFormElement>;
		for ( let i = 0; i < servicesOptions.length; i++ ) {
			if ( servicesOptions[i].checked ) {
				servicesArray.push( servicesOptions[i].value );
			}
		}

		const result = await submitData(
			form.email.value,
			form.organizationName.value,
			form.contactPerson.value,
			form.location.value,
			form.description.value,
			form.capacity.value,
			form.openingHours.value,
			servicesArray
		);

		if ( result && !result.success ) {
			const withErrors = result.messages;

			render(
				renderLocationRegistrationForm( handleSubmit, withErrors ),
				parent
			);

			return;
		}

		render(
			renderLocationRegistrationSuccess(),
			parent
		);

		document.getElementById( 'location-registration-form' )?.scrollIntoView( {
			block: 'start',
		} );

		return;
	}

	async function submitData(
		email: string,
		organizationName: string,
		contactPerson: string,
		location: string,
		description: string,
		capacity: string,
		openingHours: string,
		services: Array<string>
	): Promise<LocationRegistrationResult> {
		try {
			const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify( {
					query: REGISTER_LOCATION_MUTATION,
					variables: {
						name: organizationName,
						additionalInformation: {
							email: email,
							organizationName: organizationName,
							contactPerson: contactPerson,
							location: location,
							description: description,
							capacity: capacity,
							openingHours: openingHours,
							services: services,
						},
					},
				} ),
			} );

			if ( !response.ok ) {
				bugsnagClient.notify( new Error( `[study360 location registration] API Error: ${response.status}: ${response.statusText}` ) );

				return {
					success: false,
					messages: [
						window.translations.errorMessages.generic,
					],
				};
			}

			const responseBody = await response.json();

			if ( responseBody.data?.registerLocation === MutationFeedback.OK ) {
				return {
					success: true,
					messages: [],
				};
			}

			bugsnagClient.notify( new Error( `[study360 location registration] API Error: ${responseBody}` ) );

			const messages: string[] = [];
			responseBody.errors.forEach( ( message: string ) => {
				messages.push( message );
			} );

			return {
				success: false,
				messages: messages,
			};

		} catch ( err ) {
			return {
				success: false,
				messages: [
					window.translations.errorMessages.generic,
					err,
				],
			};
		}
	}

	render(
		renderLocationRegistrationForm( handleSubmit, [] ),
		parent
	);
}

init( (): boolean => {
	const locationRegistrationElement = document.getElementById( 'location-registration-form' );

	if ( !locationRegistrationElement ) {
		return false;
	}

	while ( locationRegistrationElement.firstChild ) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		locationRegistrationElement.removeChild( locationRegistrationElement.lastChild! );
	}

	locationRegistrationForm( locationRegistrationElement );

	return true;
} );
