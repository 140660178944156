import { html, TemplateResult } from 'lit';

export function renderLocationRegistrationForm( handleSubmit: ( e: Event ) => void, withErrors: Array<string> ): TemplateResult {
	let feedback = html``;

	if ( withErrors && 0 < withErrors.length ) {
		const messagesHTML = withErrors.map( ( error, index ) => {
			if ( index === withErrors.length - 1 ) {
				return html`${error}`;
			}

			return html`${error}<br>`;
		} );

		feedback = html`
			<div class="alert is-error">
				${messagesHTML}
			</div>
		`;
	}

	let termsAndConditionsLinkHTML = html``;
	if ( window.translations.locationRegistrationForm.termsAndConditionsLink ) {
		termsAndConditionsLinkHTML = html`
			<a href="${window.translations.locationRegistrationForm.termsAndConditionsLink.url}">
				${window.translations.locationRegistrationForm.termsAndConditionsLink.title}
			</a>
		`;
	}

	return html`
		<form
			name="form"
			class="form"
			@submit=${handleSubmit}
		>
			<div class="form-group">
				<label class="form-label" for="location-signup-email">
					${window.translations.locationRegistrationForm.emailAddress}
				</label>

				<input
					id="location-signup-email"
					name="email"
					type="email"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.emailAddress}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-organization-name">
					${window.translations.locationRegistrationForm.organizationName}
				</label>

				<input
					id="location-signup-organization-name"
					name="organizationName"
					type="text"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.organizationName}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-contact-person">
					${window.translations.locationRegistrationForm.contactPerson}
				</label>

				<input
					id="location-signup-contact-person"
					name="contactPerson"
					type="text"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.contactPerson}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-location">
					${window.translations.locationRegistrationForm.location}
				</label>

				<input
					id="location-signup-location"
					name="location"
					type="text"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.locationPlaceholder}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-description">
					${window.translations.locationRegistrationForm.description}
				</label>

				<textarea
					id="location-signup-description"
					name="description"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.descriptionPlaceholder}"
					required
				></textarea>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-capacity">
					${window.translations.locationRegistrationForm.capacity}
				</label>

				<input
					id="location-signup-capacity"
					name="capacity"
					type="number"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.capacityPlaceholder}"
					required
					min="20"
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-opening-hours">
					${window.translations.locationRegistrationForm.openingHours}
				</label>

				<textarea
					id="location-signup-opening-hours"
					name="openingHours"
					class="form-input"
					placeholder="${window.translations.locationRegistrationForm.openingHours}"
					required
				></textarea>
			</div>

			<div class="form-group">
				<label class="form-label" for="location-signup-services">
					${window.translations.locationRegistrationForm.services}
				</label>

				<div class="checkbox-group">
					${window.translations.locationRegistrationForm.servicesOptions.map( ( option ) => {
		return html`
<div class="form-checkbox">
	<input
		type="checkbox"
		name="services[]"
		value="${option.id}"
		id="services-${option.id}"
	>

	<label for="services-${option.id}">
		${option.label}
	</label>
</div>`;
	} )
}
				</div>
			</div>

			<div class="form-group form-checkbox">
				<input
					id="location-signup-accept-terms"
					name="acceptTerms"
					type="checkbox"
					required
				>

				<label for="location-signup-accept-terms">
					${window.translations.locationRegistrationForm.acceptTerms}
				</label>

				${termsAndConditionsLinkHTML}
			</div>

			${feedback}

			<div class="form-group">
				<button
					class="button"
					type="submit"
					name="submit"
				>
					${window.translations.locationRegistrationForm.registerButton}
				</button>
			</div>

		</form>
	`;
}

export function renderLocationRegistrationSuccess(): TemplateResult {
	return html`
		<div class="text-page__content u-centered">
			${window.translations.successMessages.ok}
		</div>
	`;
}
