import { templateOccupancyStudy360 } from '../templates/occupancy-study360';

type langOption = 'nl' | 'en';
const lang : langOption = ( ( document.documentElement.getAttribute( 'lang' ) ?? 'nl' ) as langOption );

async function init() {
	if ( document.querySelector( '[single-location-id]' ) ) {
		// Handled by single location query.
		return;
	}

	if ( document.querySelector( '[archive-location-id]' ) ) {
		// Handled by archive locations query.
		return;
	}

	const el = document.getElementById( 'doormat-check-in-count-students' );
	if ( !el ) {
		return;
	}

	await updateView();
	setInterval( async() => {
		await updateView();
	}, 31 * 1000 );
}

async function updateView() {
	// This can throw, but Bugsnag report frequency will tell us if it's worth providing a visible error message.
	await fetch( 'https://study360-api.mrhenry.eu/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify( {
			query: `query q {
				currentOccupationStudy360 {
					locations
					visitors
				}
			}
		`,
		} ),
	} ).then( ( response ) => {
		return response.json();
	} ).then( ( data ) => {
		if ( 0 < data?.data?.currentOccupationStudy360?.visitors ) {
			const currentOccupationEl = document.getElementById( 'doormat-check-in-count-students' );
			if ( currentOccupationEl ) {
				currentOccupationEl.innerHTML = templateOccupancyStudy360( lang, data.data.currentOccupationStudy360.locations ?? 1, data.data.currentOccupationStudy360.visitors );
			}
		}
	} ).catch( ( err ) => {
		console.warn( err );
		// non-critical functionality.
	} );
}

init();

requestAnimationFrame( () => {
	init();
} );

document.addEventListener( 'readystatechange', () => {
	init();
} );
