import '@mrhenry/wp--bugsnag-config';

// polyfills for lit
import '@mrhenry/core-web/modules/CustomEvent';
import '@mrhenry/core-web/modules/DOMTokenList';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.@@iterator';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.forEach';
import '@mrhenry/core-web/modules/DOMTokenList.prototype.replace';
import '@mrhenry/core-web/modules/DocumentFragment';
import '@mrhenry/core-web/modules/DocumentFragment.prototype.append';
import '@mrhenry/core-web/modules/Element.prototype.append';
import '@mrhenry/core-web/modules/Element.prototype.classList';
import '@mrhenry/core-web/modules/Element.prototype.getAttributeNames';
import '@mrhenry/core-web/modules/Element.prototype.matches';
import '@mrhenry/core-web/modules/Element.prototype.remove';
import '@mrhenry/core-web/modules/Event';
import '@mrhenry/core-web/modules/HTMLTemplateElement';
import '@mrhenry/core-web/modules/MediaQueryList.prototype.addEventListener';
import '@mrhenry/core-web/modules/MutationObserver';
import '@mrhenry/core-web/modules/Node.prototype.contains';
import '@mrhenry/core-web/modules/NodeList.prototype.@@iterator';
import '@mrhenry/core-web/modules/NodeList.prototype.forEach';
import '@mrhenry/core-web/modules/console';
import '@mrhenry/core-web/modules/console.error';
import '@mrhenry/core-web/modules/console.log';
import '@mrhenry/core-web/modules/matchMedia';
import '@mrhenry/core-web/modules/requestAnimationFrame';
if ( 1 === parseInt( '0', 10 ) ) { // fix to force globalThis polyfill
	// eslint-disable-next-line no-undef
	console.log( globalThis );
}
// polyfills for lit

import './modules/visitor-registration/visitor-registration';
import './modules/visitor-registration/visitor-verification';

import './modules/location-registration/location-registration';

import './modules/accordion';
import './modules/location-picker';
import './modules/sidenav';
import './modules/toggle';

import './modules/archive-location-api-data';
import './modules/doormat-count';
import './modules/location-data-error-dialog';
import './modules/location-status-indication-color';
import './modules/locations-filter';
import './modules/locations-sorter';
import './modules/single-location-api-data';
