export function mapboxLoadDependencies() {
	const mapboxVersion = 'v1.1.0'; // previously v0.53.1

	const loadJS = function() {
		return new Promise( ( resolve, reject ) => {
			const script = document.createElement( 'script' );
			script.src = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.js`;
			script.onload = () => {
				resolve();

				return;
			};
			script.onerror = () => {
				reject( new Error( 'mapbox gl js failed to load' ) );

				return;
			};
			script.async = true;

			const first = document.head.getElementsByTagName( 'script' )[0];
			first.parentNode.insertBefore( script, first );
		} );
	};

	const loadCSS = function() {
		return new Promise( ( resolve, reject ) => {
			const link = document.createElement( 'link' );
			link.rel = 'stylesheet';
			link.href = `https://api.mapbox.com/mapbox-gl-js/${mapboxVersion}/mapbox-gl.css`;
			link.onload = () => {
				resolve();

				return;
			};
			link.onerror = () => {
				reject( new Error( 'mapbox gl css failed to load' ) );

				return;
			};

			const first = document.head.getElementsByTagName( 'link' )[0];
			first.parentNode.insertBefore( link, first );
		} );
	};

	return Promise.all( [
		loadJS(),
		loadCSS(),
	] );
}

export function mapboxInitializeMap( node, center ) {
	return new Promise( ( resolve ) => {
		let zoom = 13;
		if ( window.innerWidth <= 768 ) {
			zoom = 11;
		} else if ( window.innerWidth <= 1440 ) {
			zoom = 12;
		}

		const map = new window.mapboxgl.Map( {
			container: node,
			style: 'mapbox://styles/mapbox/streets-v10?optimize=true',
			center: center,
			zoom: zoom,
		} );

		map.dragRotate.disable();
		map.touchZoomRotate.disableRotation();
		map.scrollZoom.disable();

		const nav = new window.mapboxgl.NavigationControl( {
			showCompass: false,
			showZoom: true,
		} );

		map.addControl( nav, 'top-left' );
		map.addControl( new window.mapboxgl.FullscreenControl(), 'top-left' );

		map.on( 'load', () => {
			return resolve( map );
		} );
	} );
}

export function mapboxBuildGeoJSON( locations ) {
	return {
		type: 'FeatureCollection',
		features: Array.from( locations, ( location, i ) => {
			const latitude = parseFloat( location.dataset.latitude );
			const longitude = parseFloat( location.dataset.longitude );

			if ( (
				Number.isNaN( latitude ) || Number.isNaN( longitude ) ) ||
				location.hidden
			) {
				return false;
			}

			return {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [
						longitude,
						latitude,
					],
				},
				properties: {
					locationId: location.id,
					popup: location.id,
					id: i,
				},
			};
		} ).filter( ( location ) => {
			return !!location;
		} ),
	};
}

export function mapboxRenderMarkers( geoJSON, map ) {
	if ( map.getLayer( 'markers' ) ) {
		map.removeLayer( 'markers' ).removeSource( 'markers' );
	}

	map.addLayer( {
		id: 'markers',
		type: 'circle',
		source: {
			type: 'geojson',
			data: geoJSON,
			generateId: true,
		},
		paint: {
			'circle-color': [
				'case',
				[
					'==',
					[
						'feature-state',
						'color',
					],
					'red',
				],
				'#e81830',
				[
					'==',
					[
						'feature-state',
						'color',
					],
					'green',
				],
				'#00dd00',
				'#999',
			],
			'circle-radius': 12,
			'circle-stroke-width': 1,
			'circle-stroke-opacity': 0.75,
			'circle-stroke-color': '#fff',
		},
	} );

	setTimeout( () => {
		updateFeatureState( map );

		setInterval( () => {
			updateFeatureState( map );
		}, 10_000 );
	}, 250 );
}

function updateFeatureState( map ) {
	map.queryRenderedFeatures( {
		layers: [
			'markers',
		],
	} ).forEach( ( feature ) => {
		const locationEl = document.getElementById( feature.properties.locationId );
		const color = locationEl?.dataset?.color ?? 'grey';

		map.setFeatureState( {
			source: feature.source,
			id: feature.id,
		}, {
			color: color,
		} );
	} );
}

export function mapboxRenderUserPosition( latitude, longitude, map ) {
	if ( !map.getLayer( 'user' ) ) {
		map.addLayer( {
			id: 'user',
			type: 'circle',
			source: {
				type: 'geojson',
				data: {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [
							longitude,
							latitude,
						],
					},
				},
			},
			paint: {
				'circle-color': '#eb5d0b',
				'circle-radius': 12,
				'circle-stroke-width': 1,
				'circle-stroke-opacity': 0.75,
				'circle-stroke-color': '#fff',
			},
		} );
	}

	map.flyTo( {
		center: [
			longitude,
			latitude,
		],
		bearing: 0,
		speed: 1,
	} );
}
