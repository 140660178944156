export function templateAvailableSeats( lang: string, availableSeats: number ): string {
	if ( 0 === availableSeats ) {
		if ( 'en' === lang ) {
			return 'Full';
		}

		return 'Volzet';
	}

	if ( 'en' === lang ) {
		if ( 1 === availableSeats ) {
			return '1 available seat';
		}

		return availableSeats.toString() + ' available seats';
	}

	if ( 1 === availableSeats ) {
		return '1 beschikbare plaats';
	}

	return availableSeats.toString() + ' beschikbare plaatsen';
}
