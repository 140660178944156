import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { MutationFeedback } from './data';

const VERIFY_VISITOR_MUTATION = `
	mutation (
		$token: String!,
		$language: Language!,
	) {
		verifyVisitorEmail(
			token: $token
			language: $language
		)
	}
`;

emailVerificationFlow();
async function emailVerificationFlow() {
	let token = window.location.hash;
	if ( 0 !== token.indexOf( '#verification-token=' ) ) {
		return;
	}

	token = token.slice( '#verification-token='.length );
	if ( !token ) {
		return;
	}

	try {
		const response = await fetch( 'https://study360-api.mrhenry.eu/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( {
				query: VERIFY_VISITOR_MUTATION,
				variables: {
					token: token,
					language: document.documentElement.getAttribute( 'lang' )?.toUpperCase() || 'NL',
				},
			} ),
		} );

		if ( !response.ok ) {
			errorMessage();
			clearTokenFromHash();
			bugsnagClient.notify( new Error( `[study360 verify student] API Error: ${response.status}: ${response.statusText}` ) );

			return;
		}

		const responseBody = await response.json();

		if ( responseBody.data?.verifyVisitorEmail === MutationFeedback.OK ) {
			successMessage();
			clearTokenFromHash();

			return;
		}

		if ( responseBody.data?.verifyVisitorEmail === MutationFeedback.VISITOR_NOT_FOUND ) {
			// Mostly happens because of user error, i.e. double clicking the link
			errorMessage();
			clearTokenFromHash();
			console.error( new Error( `[study360 verify student] API Error: ${JSON.stringify( responseBody )}` ) );

			return;
		}

		errorMessage();
		clearTokenFromHash();
		bugsnagClient.notify( new Error( `[study360 verify student] API Error: ${JSON.stringify( responseBody )}` ) );
	} catch ( err ) {
		console.log( err );


		errorMessage();
		clearTokenFromHash();
		bugsnagClient.notify( err );
	}
}

function clearTokenFromHash() {
	window.location.hash = '';
}

function successMessage() {
	if ( 'NL' === ( document.documentElement.getAttribute( 'lang' )?.toUpperCase() || 'NL' ) ) {
		// eslint-disable-next-line no-alert
		window.alert( 'Je e-mail is geverifieerd. Je hebt een mail ontvangen met je toegangscode en kan nu gebruik maken van Study360.' );
	} else {
		// eslint-disable-next-line no-alert
		window.alert( 'Your e-mail has been verified. You have received a mail with your access token and can now use Study360.' );
	}
}

function errorMessage() {
	if ( 'NL' === ( document.documentElement.getAttribute( 'lang' )?.toUpperCase() || 'NL' ) ) {
		// eslint-disable-next-line no-alert
		window.alert( 'E-mail verificatie mislukt. Neem contact op met Study360 indien de fout aanhoudt' );
	} else {
		// eslint-disable-next-line no-alert
		window.alert( 'E-mail verification failed. Please contact Study360 if the error persists' );
	}
}
