import { templateAvailableSeats } from '../templates/available-seats';
import { templateOpenFromTo } from '../templates/open-from-to';
import { i18nDaysOfWeek, i18nToday, i18nTomorrow } from '../templates/time';
import type { MrDialog } from '@mrhenry/wp--mr-interactive';
import { templateOccupancyStudy360 } from '../templates/occupancy-study360';
import { getIndicatorColorForLocation } from './location-status-indication-color';

type langOption = 'nl' | 'en';
const lang : langOption = ( ( document.documentElement.getAttribute( 'lang' ) ?? 'nl' ) as langOption );
let didShowErrorDialog = false;

async function init() {

	const el = document.querySelector( '[single-location-id]' );
	if ( !el ) {
		return;
	}

	const locationId = el.getAttribute( 'single-location-id' );
	if ( !locationId ) {
		return;
	}

	el.removeAttribute( 'single-location-id' );

	await updateView( locationId );
	setInterval( async() => {
		await updateView( locationId );
	}, 31 * 1000 );
}

async function updateView( locationId: string ) {
	await fetch( 'https://study360-api.mrhenry.eu/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify( {
			query: `query q($location_id: ID!) {
				currentOccupationStudy360 {
					locations
					visitors
				}
				location(id: $location_id) {
					id
					name
					currentOpeningTimeToday {
						capacity
						closingTime
						openingTime
					}
					nextOpeningTimeToday {
						capacity
						closingTime
						openingTime
					}
					openingDays {
						dayAndMonth
						dayOfWeek
						isToday
						isTomorrow
						openingTimes {
							capacity
							closingTime
							openingTime
						}
					}
					currentOccupation
					atMaximumCapacity
					calendarURL
					isCurrentlyOpen
				}
			}
			`,
			variables: {
				location_id: locationId,
			},
		} ),
	} ).then( ( response ) => {
		return response.json();
	} ).then( ( data ) => {
		if ( !( data?.data?.location ) ) {
			return;
		}

		const locationStatusColor = getIndicatorColorForLocation( data.data.location );

		document.head.querySelectorAll<HTMLLinkElement>( 'link[data-dynamic-icon]' ).forEach( ( link ) => {
			const prefix = link.getAttribute( 'data-href-prefix' );
			if ( !prefix ) {
				return;
			}

			const suffix = link.getAttribute( 'data-href-suffix' );
			if ( !suffix ) {
				return;
			}

			link.href = `${prefix}/assets/favicons/${locationStatusColor}/${suffix}`;
		} );

		const openingHoursTableEl = document.getElementById( 'opening-hours-table' );
		if ( openingHoursTableEl ) {
			openingHoursTableEl.innerHTML = tableTemplate( data.data.location.openingDays as Array<OpeningDay> );
		}

		const closedAction = document.getElementById( 'hero-action--closed' );
		if ( closedAction ) {
			if ( ( !data.data.location.currentOpeningTimeToday && !data.data.location.nextOpeningTimeToday ) ) {
				closedAction.removeAttribute( 'hidden' );
			} else {
				closedAction.setAttribute( 'hidden', '' );
			}
		}

		const openAction = document.getElementById( 'hero-action--open' );
		const openActionHours = document.getElementById( 'hero-action--open--hours' );
		if ( openAction && openActionHours ) {
			if ( data.data.location.currentOpeningTimeToday || data.data.location.nextOpeningTimeToday ) {
				openAction.removeAttribute( 'hidden' );

				const slot = data.data.location.currentOpeningTimeToday ?? data.data.location.nextOpeningTimeToday;
				openActionHours.innerHTML = templateOpenFromTo( lang, slot.openingTime, slot.closingTime );
			} else {
				openAction.setAttribute( 'hidden', '' );
				openActionHours.innerHTML = '';
			}
		}

		const seatsAction = document.getElementById( 'hero-action--available-seats' );
		const seatsActionCounter = document.getElementById( 'hero-action--available-seats--counter' );
		if ( seatsAction && seatsActionCounter ) {
			if ( data.data.location.currentOpeningTimeToday ) {
				let availableSeats = Math.max( 0, data.data.location.currentOpeningTimeToday.capacity - data.data.location.currentOccupation );
				if ( data.data.location.atMaximumCapacity ) {
					availableSeats = 0;
				}

				seatsAction.removeAttribute( 'hidden' );
				seatsActionCounter.innerHTML = templateAvailableSeats( lang, availableSeats );
			} else {
				seatsAction.setAttribute( 'hidden', '' );
				seatsActionCounter.innerHTML = '';
			}
		}

		if ( 0 < data?.data?.currentOccupationStudy360?.visitors ) {
			const currentOccupationEl = document.getElementById( 'doormat-check-in-count-students' );
			if ( currentOccupationEl ) {
				currentOccupationEl.innerHTML = templateOccupancyStudy360( lang, data.data.currentOccupationStudy360.locations ?? 1, data.data.currentOccupationStudy360.visitors );
			}
		}
	} ).catch( ( err ) => {
		console.warn( err );

		if ( !didShowErrorDialog ) {
			didShowErrorDialog = true;
			( document.getElementById( 'calendar-data-error-dialog' ) as MrDialog | null )?.updateState( 'open' );
		}
	} );
}

type OpeningDay = {
	date: string,
	dayAndMonth: string,
	dayOfWeek: number,
	isToday: boolean,
	isTomorrow: boolean,
	openingTimes?: Array<OpeningTime>,
}

type OpeningTime = {
	capacity: number,
	closingTime: string,
	date: string,
	openingTime: string
}

function tableTemplate( days: Array<OpeningDay> ) {
	let rows = '';

	for ( let i = 0; i < days.length; i++ ) {
		const day = days[i];

		let dayOfWeekLabel = '';
		if ( day.isToday ) {
			dayOfWeekLabel = i18nToday[lang];
		} else if ( day.isTomorrow ) {
			dayOfWeekLabel = i18nTomorrow[lang];
		} else {
			dayOfWeekLabel = `${i18nDaysOfWeek[lang][day.dayOfWeek]} ${day.dayAndMonth}`;
		}

		rows += templateTableRow( dayOfWeekLabel, day.openingTimes ?? [] );
	}

	return `
		<table class="opening-hours">
			<tbody>
				${rows}
			</tbody>
		</table>
	`;
}


function templateTableRow( dayOfWeekLabel: string, times: Array<OpeningTime> ) {
	const slotsFormatted = times.map( ( time ) => {
		return `<span>${time.openingTime} - ${time.closingTime}</span>`;
	} ).join( '' );

	const seats = {
		nl: 'plaatsen',
		en: 'seats',
	};

	const capacitiesFormatted = times.map( ( time ) => {
		return `<span>${time.capacity} ${seats[lang]}</span>`;
	} ).join( '' );

	let closedLabel = 'Gesloten';
	if ( 'en' === lang ) {
		closedLabel = 'Closed';
	}

	let slotsHTML = `
		<td class="opening-hours__hours">
			<span>${closedLabel}</span>
		</td>`;

	let capacitiesHTML = '<td class="opening-hours__capacities"></td>';

	if ( 0 < times.length ) {
		slotsHTML = `
			<td class="opening-hours__hours">
				${slotsFormatted}
			</td>`;

		capacitiesHTML = `
			<td class="opening-hours__seats">
				${capacitiesFormatted}
			</td>`;
	}

	return `
		<tr>
			<td class="opening-hours__day">${dayOfWeekLabel}</td>

			${slotsHTML}

			${capacitiesHTML}
		</tr>
	`;
}

init();

requestAnimationFrame( () => {
	init();
} );

document.addEventListener( 'readystatechange', () => {
	init();
} );
