enum LocationStatusIndicatorColor {
	/**
	 * The location is closed, or the data is not available.
	 */
	Grey = 'grey',
	/**
	 * The location is open but no space is  available.
	 * Either the `atMaximumCapacity` flag has been set or
	 * the `currentOccupation` equals `capacity`.
	 */
	Red = 'red',
	/**
	 * The location is open and space is available.
	 */
	Green = 'green'
}

type LocationDataForStatus = {
	isCurrentlyOpen: boolean,
	currentOpeningTimeToday: {
		capacity: number,
	},
	currentOccupation: number,
	atMaximumCapacity: boolean,
}

function isDataForLocationStatus( location: unknown ): location is LocationDataForStatus {
	return typeof location === 'object' &&
		location !== null &&
		'isCurrentlyOpen' in location &&
		'currentOpeningTimeToday' in location &&
		'currentOccupation' in location &&
		'atMaximumCapacity' in location;
}

export function getIndicatorColorForLocation( location: unknown ): LocationStatusIndicatorColor {
	if ( !isDataForLocationStatus( location ) ) {
		return LocationStatusIndicatorColor.Grey;
	}

	if ( !location.isCurrentlyOpen ) {
		return LocationStatusIndicatorColor.Grey;
	}

	if ( !location.currentOpeningTimeToday ) {
		return LocationStatusIndicatorColor.Grey;
	}

	if ( location.atMaximumCapacity ) {
		return LocationStatusIndicatorColor.Red;
	}

	if ( location.currentOpeningTimeToday.capacity <= location.currentOccupation ) {
		return LocationStatusIndicatorColor.Red;
	}

	return LocationStatusIndicatorColor.Green;
}
