class MrLocationsSorter extends HTMLElement {
	#changeHandler = ( e: Event ) => {
		const target = e.target;

		if ( !( target instanceof HTMLSelectElement ) ) {
			return;
		}

		if ( !target.hasAttribute( 'filter-location-sorting' ) ) {
			return;
		}

		const locations = Array.from( document.querySelectorAll<HTMLElement>( '[sortable]' ) );
		if ( !locations ) {
			return;
		}

		let order = 'ASC';
		let sortAttribute = 'sort-by-alphabet';

		if ( target.value === 'capacity' ) {
			sortAttribute = 'sort-by-value';
			order = 'DESC';
		}

		const direction = ( x: number ) => {
			if ( order === 'ASC' ) {
				return x;
			}

			return -x;
		};

		// The actual sorting.
		const sortableAttributeValues = new Map();

		locations.forEach( ( location ) => {
			sortableAttributeValues.set( location, location.getAttribute( sortAttribute ) ?? '0' );
		} );

		locations.sort( ( a, b ) => {
			let aSortValue = sortableAttributeValues.get( a );
			let bSortValue = sortableAttributeValues.get( b );

			if ( sortAttribute === 'sort-by-value' ) {
				aSortValue = parseInt( aSortValue, 10 );
				bSortValue = parseInt( bSortValue, 10 );
			}

			if ( aSortValue < bSortValue ) {
				return direction( -1 );
			}

			if ( aSortValue > bSortValue ) {
				return direction( 1 );
			}

			return 0;
		} );

		locations.forEach( ( el ) => {
			el.parentElement?.appendChild( el );
		} );
	};

	connectedCallback() {
		this.addEventListener( 'change', this.#changeHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'change', this.#changeHandler );
	}
}

customElements.define( 'mr-locations-sorter', MrLocationsSorter );
