import { html, TemplateResult } from 'lit';

export function renderVisitorRegistrationForm( handleSubmit: ( e: Event ) => void, withErrors: Array<string> ): TemplateResult {
	let feedback = html``;

	if ( withErrors && 0 < withErrors.length ) {
		const messagesHTML = withErrors.map( ( error, index ) => {
			if ( index === withErrors.length - 1 ) {
				return html`${error}`;
			}

			return html`${error}<br>`;
		} );

		feedback = html`
			<div class="alert is-error">
				${messagesHTML}
			</div>
		`;
	}

	let termsAndConditionsLinkHTML = html``;
	if ( window.translations.registrationForm.termsAndConditionsLink ) {
		termsAndConditionsLinkHTML = html`
			<a href="${window.translations.registrationForm.termsAndConditionsLink.url}">
				${window.translations.registrationForm.termsAndConditionsLink.title}
			</a>
		`;
	}

	let privacyPolicyLinkHTML = html``;
	if ( window.translations.registrationForm.privacyPolicyLink ) {
		privacyPolicyLinkHTML = html`
			<a href="${window.translations.registrationForm.privacyPolicyLink.url}">
				${window.translations.registrationForm.privacyPolicyLink.title}
			</a>
		`;
	}

	return html`
		<form
			name="form"
			class="form"
			@submit=${handleSubmit}
		>
			<div class="form-group">
				<label class="form-label" for="user_email">
					${window.translations.registrationForm.emailAddress}
				</label>

				<input
					id="user_email"
					name="email"
					type="email"
					class="form-input"
					placeholder="${window.translations.registrationForm.emailAddress}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="first_name">
					${window.translations.registrationForm.firstName}
				</label>

				<input
					id="first_name"
					name="firstName"
					type="text"
					class="form-input"
					placeholder="${window.translations.registrationForm.firstName}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="lastName">
					${window.translations.registrationForm.lastName}
				</label>

				<input
					id="last_name"
					name="lastName"
					type="text" class="form-input"
					placeholder="${window.translations.registrationForm.lastName}"
					required
				>
			</div>

			<div class="form-group">
				<label class="form-label" for="phone_number">
					${window.translations.registrationForm.phoneNumber}
				</label>

				<input
					id="phone_number"
					name="phoneNumber"
					type="text"
					class="form-input"
					placeholder="${window.translations.registrationForm.phoneNumber}"
					required
				>
			</div>

			<div class="form-group form-group--field-note">
				<p>
					${window.translations.registrationForm.covidMessage}
				</p>
			</div>

			<div class="form-group">
				<label class="form-label" for="student_number">
					${window.translations.registrationForm.studentNumber}
				</label>

				<input
					id="student_number"
					name="studentNumber"
					type="text"
					class="form-input"
					placeholder="${window.translations.registrationForm.studentNumber}"
					required
				>
			</div>

			<div class="form-group has-select">
				<label class="form-label" for="school">
					${window.translations.registrationForm.school}
				</label>

				<select
					class="form-input form-input--select"
					id="school"
					name="school"
					required
				>
					<option value="" disabled>Onderwijsinstelling</option>
					<option value="Antwerp Management School">${window.translations.registrationForm.schoolOptions[0]}</option>
					<option value="AP Hogeschool">${window.translations.registrationForm.schoolOptions[1]}</option>
					<option value="Hogere Zeevaartschool">${window.translations.registrationForm.schoolOptions[2]}</option>
					<option value="Instituut voor Tropische Geneeskunde">${window.translations.registrationForm.schoolOptions[3]}</option>
					<option value="Karel de Grote Hogeschool">${window.translations.registrationForm.schoolOptions[4]}</option>
					<option value="KU Leuven Campus Antwerpen">${window.translations.registrationForm.schoolOptions[5]}</option>
					<option value="Thomas More Antwerpen">${window.translations.registrationForm.schoolOptions[6]}</option>
					<option value="Universiteit Antwerpen">${window.translations.registrationForm.schoolOptions[7]}</option>
					<option value="Andere onderwijsinstelling">${window.translations.registrationForm.schoolOptions[8]}</option>
				</select>
			</div>

			<div class="form-group">
				<label class="form-label" for="education">
					${window.translations.registrationForm.education}
				</label>

				<input
					id="education"
					name="education"
					type="text"
					class="form-input"
					placeholder="${window.translations.registrationForm.education}"
					required
				>
			</div>

			<div class="form-group form-checkbox">
				<input
					id="communication_opt_in"
					name="communicationOptIn"
					type="checkbox"
				>

				<label for="communication_opt_in">
					${window.translations.registrationForm.communicationOptIn1}
					<br><br>
					${window.translations.registrationForm.communicationOptIn2}
				</label>

				${privacyPolicyLinkHTML}
			</div>

			<div class="form-group form-checkbox">
				<input
					id="accept_terms"
					name="accept_terms"
					type="checkbox"
					required
				>

				<label for="accept_terms">${window.translations.registrationForm.acceptTerms}</label>

				${termsAndConditionsLinkHTML}
			</div>

			${feedback}

			<div class="form-group">
				<button
					class="button"
					type="submit"
					name="submit"
				>
					${window.translations.registrationForm.registerButton}
				</button>
			</div>
		</form>
	`;
}

export function renderVisitorRegistrationSuccess(): TemplateResult {
	return html`
		<div class="text-page__content u-centered">
			${window.translations.successMessages.ok}
		</div>
	`;
}
