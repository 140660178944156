const didInitFor: WeakMap<() => boolean, boolean> = new WeakMap();

export function init( callback: () => boolean ): void {
	if ( callback() ) {
		return;
	}

	const doOnce = () => {
		if ( didInitFor.has( callback ) ) {
			return;
		}

		const didInit = callback();
		if ( didInit ) {
			didInitFor.set( callback, true );

			document.removeEventListener( 'DOMContentLoaded', doOnce );
			document.removeEventListener( 'readystatechange', doOnce );
			window.removeEventListener( 'load', doOnce );
		}
	};

	requestAnimationFrame( doOnce );
	document.addEventListener( 'DOMContentLoaded', doOnce );
	document.addEventListener( 'readystatechange', doOnce );
	window.addEventListener( 'load', doOnce );
}
